export const home = '/';
export const about = '/about';
export const contact = '/contact';
export const career = '/career';
export const sustainability = '/sustainability';
export const view = '/view/:id';
export const news = '/news';
export const blog = '/blog';
export const event = '/event';
export const team = '/team';
export const subsidiary = '/subsidiaries';
export const patecFood = '/patec-food';
export const qualityPackaging = '/quality-packaging';
export const coloradoFarm = '/colorado-farms';
export const goldenFood = '/golden-food';
export const traveliFood = '/traveli-food';
